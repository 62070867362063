<template>
  <div :class="$style.container">
    <div :class="$style.wrapper">
      <div>
        <router-link
          :to="
            getRoute({
              route: $options.ADDSELLERS_ADMIN_ROUTES.DEALERS.CREATE,
            })
          "
          :class="$style.create"
        >
          <Icon name="plus" :class="$style.plus" /> Создать дилера
        </router-link>
      </div>
      <div :class="$style.left">
        <Autocomplete
          :class="$style.search"
          :search="search"
          placeholder="Поиск по имени, фамилии, телефона и почты получателей дилера"
          :valueNames="['name']"
          @querySearch="querySearch"
          @selectItem="handleSelectDealer"
          @handleFindItems="getDealers"
          @input="search = $event"
        />
        <Autocomplete
          :class="$style.search"
          :search="searchRequisites"
          placeholder="Поиск по названию УНП, телефону и почте реквизитов"
          :valueNames="['name']"
          @querySearch="querySearchRequisites"
          @selectItem="handleSelectDealer"
          @handleFindItems="getDealers"
          @input="searchRequisites = $event"
        />
        <Autocomplete
          :class="$style.search"
          :search="searchRecipients"
          placeholder="Поиск по email и названию компании дилера"
          :valueNames="['name']"
          @querySearch="querySearchRecipients"
          @selectItem="handleSelectDealer"
          @handleFindItems="getDealers"
          @input="searchRecipients = $event"
        />
      </div>
      <div :class="$style.right">
        <el-button
          :class="$style.button"
          type="primary"
          @click="handleClickFilters"
        >
          Фильтры
        </el-button>
        <el-button :class="$style.button" type="primary" @click="getDealers">
          Поиск
        </el-button>
        <DropdownDealerFilters
          v-model="filters"
          v-if="isFiltersOpen"
          title="Фильтры поиска дилеров"
          :isOpen="isFiltersOpen"
          :options="filtersOptions"
          @applyFilters="applyFilters"
          @resetFilters="resetFilters"
          @close="isFiltersOpen = false"
          @loadMoreManagers="loadMoreManagers"
          @loadMorePrices="loadMorePrices"
          @loadMoreManagersCoordinators="loadMoreManagersCoordinators"
        />
      </div>
    </div>
    <div :class="$style.tableWrapper">
      <vue-good-table
        :class="[$style.table]"
        :columns="columns"
        :rows="dealers"
        styleClass="vgt-table striped"
        fixed-header
        @on-sort-change="onSortChange"
      >
        >
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field == 'isRequesitesVerified'"
            :class="$style.blockStatus"
          >
            <div
              :class="[
                $style.status,
                {
                  [$style.successStatus]: props.row.isRequisitesVerified,
                },
              ]"
            />
          </span>
          <span v-else-if="props.column.field == 'CreatedAt'">
            {{ fomateDate(props.row.createdAt) }}
          </span>
          <router-link
            :to="
              getRoute({
                route: $options.MANAGERS_ROUTES.UPDATE,
                params: { id: props.row.managerAccountId },
              })
            "
            v-else-if="props.column.field == 'managerAccount' && isOwner"
          >
            {{ props.row.managerAccount.name }}
          </router-link>
          <span v-else-if="props.column.field == 'managerAccount' && !isOwner">
            {{ props.row.managerAccount?.name }}
          </span>
          <span v-else-if="props.column.field === 'dealerCatalog'">
            {{ props.row.dealerCatalog ? props.row.dealerCatalog.name : '-' }}
          </span>
          <a
            href="/"
            v-else-if="props.column.field === 'История'"
            @click.prevent="openNotesSidebar(props.row.id, props.row.name)"
          >
            История
          </a>
          <router-link
            v-else-if="props.column.field == 'Все реквизиты'"
            :to="
              getRoute({
                route:
                  $options.ADDSELLERS_ADMIN_ROUTES.DEALERS.CLIENT_REQUESITES
                    .INDEX,
                params: { id: props.row.id },
              })
            "
          >
            Все реквизиты
          </router-link>
          <router-link
            v-else-if="props.column.field == 'Все заказы'"
            :to="
              getRoute({
                route: $options.ADDSELLERS_ADMIN_ROUTES.DEALERS.ORDERS.LIST,
                params: { id: props.row.id },
              })
            "
          >
            Все заказы
          </router-link>
          <router-link
            v-else-if="props.column.field == 'Все адреса доставки'"
            :to="
              getRoute({
                route:
                  $options.ADDSELLERS_ADMIN_ROUTES.DEALERS.DELIVERY_ADDRESSES
                    .INDEX,
                params: { id: props.row.id },
              })
            "
          >
            Все адреса доставки
          </router-link>
          <router-link
            v-else-if="props.column.field == 'Все получатели'"
            :to="
              getRoute({
                route:
                  $options.ADDSELLERS_ADMIN_ROUTES.DEALERS.RECIPIENTS.INDEX,
                params: { id: props.row.id },
              })
            "
          >
            Все получатели
          </router-link>
          <router-link
            v-else-if="props.column.field == 'Бонусы' && isAdminOrOwner"
            :to="
              getRoute({
                route:
                  $options.ADDSELLERS_ADMIN_ROUTES.DEALERS.BONUS_TRANSACTIONS
                    .LIST,
                params: { id: props.row.id },
              })
            "
          >
            Бонусы
          </router-link>
          <a
            v-else-if="props.column.field == 'Удалить дилера' && isAdminOrOwner"
            href=""
            title="Удалить дилера"
            @click.prevent="deleteDealer(props.row)"
          >
            <!-- prettier-ignore -->
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
          </a>
          <router-link
            :to="
              getRoute({
                route: $options.ADDSELLERS_ADMIN_ROUTES.DEALERS.EDIT,
                params: { id: props.row.id },
              })
            "
            v-else-if="props.column.field == 'edit'"
          >
            <Icon v-if="isAdminOrOwner" name="edit" :class="$style.icon" />
            <Icon v-else name="eye" :class="$style.icon" />
          </router-link>
        </template>
      </vue-good-table>
      <DealerSidebar
        :isOpen="isOpenNotesSidebar"
        :dealerId="dealerId"
        :dealerName="dealerName"
        @toggle="isOpenNotesSidebar = $event"
      />
    </div>
    <Pagination
      v-if="pageCount > 1"
      @go-next="goNext()"
      @go-prev="goPrev()"
      :curPage="page"
      :lastPage="pageCount"
    />
  </div>
</template>

<script>
import Autocomplete from '@/components/atoms/Autocomplete'
import Icon from '@/components/atoms/Icon'
import Pagination from '@/components/atoms/Paginations.vue'
import DealerSidebar from '@/components/sellers/organisms/DealerSidebar.vue'
import delivery from '@/delivery'

import DropdownDealerFilters from '@/components/atoms/DropdownDealerFilters'
import { DEALERS_BOOLEANS } from '@/constants/dealers'
import {
  ADDSELLERS_ADMIN_ROUTES,
  getRoute,
  MANAGERS_ROUTES,
} from '@/constants/routing'
import { formatDate } from '@/helpers/index.js'
import notifications from '@/mixins/notifications'
import { mapGetters } from 'vuex'
export default {
  components: {
    DealerSidebar,
    Pagination,
    Icon,
    DropdownDealerFilters,
    Autocomplete,
  },
  mixins: [notifications],
  ADDSELLERS_ADMIN_ROUTES,
  MANAGERS_ROUTES,
  mounted() {
    this.searchItem = this.$route.query.search
    this.getFiltersOptions()
    this.getDealersList()
  },
  computed: {
    ...mapGetters({
      isAdminOrOwner: 'isAdminOrOwner',
      isOwner: 'isOwner',
    }),
    pageCount() {
      return Math.ceil(this.total / this.limit)
    },
  },
  data() {
    return {
      search: '',
      searchRecipients: '',
      searchRequisites: '',
      isFiltersOpen: false,
      isOpenNotesSidebar: false,
      dealerId: '',
      dealerName: '',
      filtersOptions: {
        limit: 10,
        booleans: DEALERS_BOOLEANS,
        managersCoordinators: [],
        types: [],
        pricesPolicy: [],
        managers: [],
        catalogs: [],
        createdManager: [],
      },
      filters: {
        isBanned: null,
        ieCartDiscountIsAvailable: null,
        llcCartDiscountIsAvailable: null,
        rwCartDiscountIsAvailable: null,
        memberBP: null,
        isSupplier: null,
        startAt: '',
        endAt: '',
        type: '',
        pricePolicyId: '',
        dealerCatalogId: '',
        managerId: '',
        managerCoordinatorId: '',
      },
      columns: [
        {
          label: '',
          field: 'isRequesitesVerified',
          sortable: false,
        },
        {
          label: 'Название компании',
          field: 'name',
        },
        {
          label: 'Дата создания',
          field: 'CreatedAt',
        },
        {
          label: 'Менеджер',
          field: 'managerAccount',
          sortable: false,
        },
        {
          label: 'Каталог',
          field: 'dealerCatalog',
          sortable: false,
        },
        {
          label: '',
          field: 'История',
          sortable: false,
        },
        {
          label: '',
          field: 'Все реквизиты',
          sortable: false,
        },
        {
          label: '',
          field: 'Все заказы',
          sortable: false,
        },
        {
          label: '',
          field: 'Все адреса доставки',
          sortable: false,
        },
        {
          label: '',
          field: 'Все получатели',
          sortable: false,
        },
        {
          label: '',
          field: 'Бонусы',
          sortable: false,
        },
        {
          label: '',
          field: 'Удалить дилера',
          sortable: false,
        },
        {
          label: '',
          field: 'edit',
          sortable: false,
        },
      ],
      limit: 20,
      page: 1,
      total: 0,
      searchItem: '',
      searchProducts: [],
      dealers: [],
      isShow: false,
      isSearch: false,
      isLoading: false,
      orderBy: null,
      orderDir: null,
    }
  },
  methods: {
    openNotesSidebar(id, name) {
      this.dealerId = id
      this.dealerName = name
      this.isOpenNotesSidebar = true
    },
    async loadMoreManagers() {
      if (
        this.filtersOptions.limit * this.filtersOptions.managers.meta.page <=
        this.filtersOptions.managers.meta.count
      ) {
        this.filtersOptions.managers.meta.page++
        const query = {
          page: this.filtersOptions.managers.meta.page,
          coordinators: false,
          limit: this.filtersOptions.limit,
        }
        const { value } = await delivery.AddwineCore.ManagersActions.getList(
          query,
        )
        value.data.forEach((manager) => {
          if (!this.filtersOptions.managers.data.find((x) => x === manager))
            this.filtersOptions.managers.data.push(manager)
        })
      }
    },
    async loadMoreManagersCoordinators() {
      if (
        this.filtersOptions.limit *
          this.filtersOptions.managersCoordinators.meta.page <=
        this.filtersOptions.managersCoordinators.meta.count
      ) {
        this.filtersOptions.managersCoordinators.meta.page++
        const query = {
          page: this.filtersOptions.managersCoordinators.meta.page,
          coordinators: true,
          limit: this.filtersOptions.limit,
        }
        const { value } = await delivery.AddwineCore.ManagersActions.getList(
          query,
        )
        value.data.forEach((manager) => {
          if (
            !this.filtersOptions.managersCoordinators.data.find(
              (x) => x === manager,
            )
          )
            this.filtersOptions.managersCoordinators.data.push(manager)
        })
      }
    },
    async loadMorePrices() {
      if (
        this.filtersOptions.limit *
          this.filtersOptions.pricesPolicy.meta.page <=
        this.filtersOptions.pricesPolicy.meta.count
      ) {
        this.filtersOptions.pricesPolicy.meta.page++
        const query = {
          page: this.filtersOptions.pricesPolicy.meta.page,
          limit: this.filtersOptions.limit,
        }
        const { value } = await delivery.AddwineCore.PricePolicyActions.getList(
          query,
        )
        value.data.forEach((price) => {
          if (!this.filtersOptions.pricesPolicy.data.find((x) => x === price))
            this.filtersOptions.pricesPolicy.data.push(price)
        })
      }
    },
    async querySearch({ queryString, setSearchItems }) {
      const query = {
        limit: this.limit,
        search: queryString,
      }
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getSearchList(query)
      if (error) return
      setSearchItems(value.data)
    },
    async querySearchRecipients({ queryString, setSearchItems }) {
      const query = {
        limit: this.limit,
        searchRecipients: queryString,
      }
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getSearchList(query)
      if (error) return
      setSearchItems(value.data)
    },
    async querySearchRequisites({ queryString, setSearchItems }) {
      const query = {
        limit: this.limit,
        searchRequisites: queryString,
      }
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getSearchList(query)
      if (error) return
      setSearchItems(value.data)
    },
    handleSelectDealer(selectedProduct) {
      this.$router.push(
        getRoute({
          route: ADDSELLERS_ADMIN_ROUTES.DEALERS.EDIT,
          params: {
            id: selectedProduct.id,
          },
        }),
      )
    },
    async getFiltersOptions() {
      const loading = this.$loading({
        lock: true,
      })
      const {
        managers,
        managersCoordinators,
        pricesPolicy,
        catalogList,
        types,
      } = await delivery.AddwineCore.DealersActions.getDealersFilters()

      this.filtersOptions.types = types
      this.filtersOptions.pricesPolicy = pricesPolicy
      this.filtersOptions.managers = managers
      this.filtersOptions.managersCoordinators = managersCoordinators
      this.filtersOptions.catalogs = catalogList
      loading.close()
    },
    async getDealers() {
      this.page = 1
      await this.getDealersList()
    },
    handleClickFilters() {
      this.isFiltersOpen = !this.isFiltersOpen
    },
    async applyFilters() {
      this.getDealers()
      this.isFiltersOpen = false
    },
    resetFilters() {
      this.filters = {
        isBanned: null,
        ieCartDiscountIsAvailable: null,
        llcCartDiscountIsAvailable: null,
        rwCartDiscountIsAvailable: null,
        memberBP: null,
        isSupplier: null,
        startAt: '',
        endAt: '',
        type: '',
        pricePolicyId: '',
        dealerCatalogId: '',
        managerId: '',
        managerCoordinatorId: '',
      }
    },
    async goNext() {
      if (this.page < this.total) {
        this.page += 1
        await this.getDealersList()
      }
    },
    fomateDate(date) {
      return formatDate(date)
    },
    async goPrev() {
      if (this.page > 1) {
        this.page -= 1
        await this.getDealersList()
      }
    },
    async deleteDealer(dealer) {
      if (
        confirm(
          `Вы действительно хотите навсегда удалить дилера "${dealer.name}"?`,
        )
      ) {
        const result = await delivery.AddwineCore.DealersActions.delete(
          dealer.id,
        )

        if (result.error) {
          alert('Ошибка удаления дилера!')
        } else {
          this.getDealersList()
        }
      }
    },
    async submitSearch() {
      this.isSearch = this.searchItem ? true : false
      this.isShow = false
      this.page = 1

      this.isLoading = true
      await this.getDealersList()
      this.isLoading = false
    },
    async clearSearch() {
      this.isShow = false
      this.isSearch = false
      this.searchItem = ''
      this.page = 1
      this.isLoading = true
      this.$router.replace({ query: null })
      await this.getDealersList()
      this.isLoading = false
    },
    async onSortChange(params) {
      let orderBy = null
      let orderDir = null
      if (params[0].type !== 'none') {
        orderBy = params[0].field
        orderDir = params[0].type
        if (params[0].field === 'CreatedAt') {
          orderBy = 'created_at'
        }
      }
      this.page = 1
      this.orderBy = orderBy
      this.orderDir = orderDir
      await this.getDealersList()
    },
    async getDealersList() {
      const loading = this.$loading({
        lock: true,
      })
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getList({
          page: this.page,
          limit: this.limit,
          search: this.search,
          orderBy: this.orderBy,
          orderDir: this.orderDir,
          searchRecipients: this.searchRecipients,
          searchRequisites: this.searchRequisites,
          ...this.filters,
        })
      if (error) return

      this.isShow = false
      this.dealers = value.data
      this.total = value.meta.count
      loading.close()
    },
    getRoute({ route, params }) {
      return getRoute({ route, params })
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  .create {
    @include stickyWrapper;
    z-index: 1000;
    display: inline-flex;
    align-items: center;
    padding: 1rem;
    border: 1px solid $light-gray;
    margin-bottom: 2rem;
    gap: 0.5rem;
    text-decoration: none;
    color: $dark-gray;
    &:hover {
      background: $white;
    }
  }
  h2 {
    padding: 1rem 0;
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    gap: 1rem;
    @include stickyWrapperCustom('flex-start', 20, 3rem);

    a {
      display: flex;
      margin-bottom: 0;
    }
    .left {
      margin-right: 0.75rem;
      .search {
        margin: 0;
        &:not(:last-child) {
          margin-right: 0.75rem;
        }
      }
    }
    .right {
      position: relative;
      z-index: $z-index-drodpown;
      .button:not(:first-child) {
        margin-left: 0.75rem;
      }
    }
  }

  .tableWrapper {
    display: flex;

    .table {
      width: 100%;

      &.lessWidth {
        max-width: 50%;
      }
      .icon {
        color: $black;
        fill: currentColor;
      }
    }
  }
  .blockStatus {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .status {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $error;
    transform: translate(100%, 100%);
  }
  .successStatus {
    background-color: $complite;
  }
  .plus {
    fill: $light-gray;
    width: 1rem;
    height: 1rem;
  }
  .search {
    margin-bottom: 2rem;
    max-width: 55.5rem;
  }
  .item {
    display: flex;
    align-items: center;

    border-bottom: 0.063rem solid $extra-light-gray;
    &:last-child {
      border-bottom: none;
    }

    .link {
      display: grid;
      grid-template-columns: 1.25rem 1fr 1fr 1fr;
      gap: 0 0.5rem;
      text-decoration: none;
      width: 100%;
      padding: 1rem 0;
      color: $black-gray;
    }
  }
}
</style>
